
import { mapGetters, mapActions } from "vuex";
import BaseBreadCrumbs from "@/components/shared/breadcrumbs/BaseBreadCrumbs.vue";
import { FormatDate } from "@/shared/helpers/dateHelpers";
import { HoursTableColumns } from "@/shared/utils/tableHeaders";
import { FormatList } from "@/shared/helpers/filterHelpers";
import { defineComponent } from "@vue/composition-api";

interface ComponentData {
  search: string;
  group: any;
  showHoursDetailsModal: boolean;
}

export default defineComponent({
  name: "HoursListPage",
  components: { BaseBreadCrumbs },
  data: function (): ComponentData {
    return {
      search: "",
      group: "date",
      showHoursDetailsModal: false,
    };
  },
  computed: {
    ...mapGetters("hours", ["getHours"]),
    tableHeaders() {
      return FormatList(HoursTableColumns, "text");
    },
    hourItems() {
      let hoursTable: any[] = [];
      (this as any).getHours.forEach((item: any, index: any) => {
        const date = FormatDate(item.start);
        const hourItem = {
          id: index,
          date: date,
          courseId: item.courseId,
          scheduleStart: item.scheduleStart,
          name: item.courseName,
          pdId: 300,
          pdName: "Undervisningstimer",
          pdSubTypeId: 301,
          pdSubTypeName: "Undervisningstimer 1-2",
          title: item.title,
          status: "Ny",
          registeredHours: "",
          approvedHours: "",
          comment: "",
        };
        hoursTable.push(hourItem);
      });
      return hoursTable;
    },
  },
  methods: {
    ...mapActions("hours", ["fetchHours"]),
    ...mapActions("course", ["reset", "fetchCourse"]),
    gropuNone() {
      this.group = undefined;
    },
    gropuDate() {
      this.group = "date";
    },
    groupCourse() {
      this.group = "name";
    },
    handleHour(event: any) {
      (this as any).reset();
      (this as any).fetchCourse({ courseId: event.courseId });
      this.showHoursDetailsModal = true;
    },
    getColor(factor: any) {
      if (factor === 100) {
        return "green";
      }
      if (factor < 100 && factor > 0) {
        return "orange";
      }
      return "red";
    },
  },
  async created() {
    await (this as any).fetchHours();
  },
});
